import { config } from "./config";

export function getSignalingUrl(peerId, roomId) {
  const isDev = process.env.NODE_ENV !== "production";
  const hostname = isDev
    ? window.location.hostname
    : config.serverHostname || window.location.hostname;
  const port = isDev ? config.developmentPort : config.productionPort;
  const hostAndOrPort = isDev ? `${hostname}:${port}` : hostname;
  const url = `wss://${hostAndOrPort}/?peerId=${peerId}&roomId=${roomId}`;

  return url;
}
