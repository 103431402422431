import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  createStyles,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { env } from "../../configs/environment.config";
import authService from "../../services/auth.services";
import meetingService from "../../services/meeting.service";
import DisplayName from "../JoinMeeting/DisplayName";

const styles = makeStyles(() =>
  createStyles({
    root: {
      width: "100vw",
      height: "100vh",
      backgroundImage: `url(${config.homeBackground})`,
      overflow: "hidden"
    },
    container: {
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.9)"
    },
    cardContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "-90px",
      alignItems: "center"
    },
    card: {
      width: "815px",
      height: "563px",
      paddingLeft: "20px"
    },
    loggedInCard: {
      width: "815px",
      height: "563px",
      display: "grid",
      placeItems: "center",
      textAlign: "center"
    },
    dashboardButton: {
      color: "#07AA3D",
      fontSize: "20px",
      textTransform: "capitalize",
      borderRadius: 0,
      marginLeft: "10px",
      borderBottom: "1.5px solid #07AA3D",
      "&:hover": {
        color: "#08cc46",
        borderRadius: "5px",
        background: "white"
      }
    },
    details: {
      fontSize: "24px",
      width: "650px",
      fontWeight: 300,
      color: "#8692A6"
    },
    infoIcons: {
      width: "100%",
      display: "flex",
      margin: "20px 10px",
      justifyContent: "end",
      justifyItems: "space-between"
    },
    input: {
      width: "67%",
      border: "1px solid #48484A",
      padding: "0 30px",
      borderRadius: "6px"
    },
    loggedInInput: {
      width: "80%",
      height: "64px",
      border: "1px solid #48484A",
      padding: "0 30px",
      margin: "20px 0",
      borderRadius: "6px"
    },
    inputContainer: {
      width: "100%",
      height: "65px",
      display: "flex",
      justifyContent: "space-between",
      margin: "30px 0"
    },
    joinButton: {
      background: "#07AA3D",
      width: "25%",
      marginRight: "35px",
      textTransform: "capitalize",
      fontSize: "20px",
      color: "white",
      "&:hover": {
        background: "#08cc46"
      }
    },
    loggedInJoinButton: {
      background: "#07AA3D",
      width: "30%",
      height: "53px",
      marginTop: "35px",
      textTransform: "capitalize",
      fontSize: "20px",
      color: "white",
      "&:hover": {
        background: "#08cc46"
      }
    },
    logo: {
      height: 50,
      width: 150
    },
    logoContainer: {
      padding: "70px 70px 0 85px",
      display: "flex",
      justifyContent: "space-between"
    },
    title: {
      width: "550px",
      fontSize: "50px",
      fontWeight: 400,
      lineHeight: "120%",
      marginBottom: "20px"
    },
    "@media only screen and (min-device-width: 330px) and (max-device-width: 768px)": {
      container: {
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.9)"
      },
      cardContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "-90px",
        alignItems: "center"
      },
      card: {
        width: "100%",
        height: "550px"
      },
      details: {
        fontSize: "20px",
        width: "100%",
        fontWeight: 300,
        color: "#8692A6"
      },
      input: {
        width: "100%",
        border: "1px solid #48484A",
        padding: "0 18px",
        borderRadius: "6px"
      },
      inputContainer: {
        width: "100%",
        height: "65px",
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        margin: "30px 0"
      },
      joinButton: {
        background: "#07AA3D",
        width: "100%",
        marginRight: "35px",
        textTransform: "capitalize",
        fontSize: "20px",
        color: "white",
        "&:hover": {
          background: "#08cc46"
        }
      },
      logoContainer: {
        padding: "40px",
        display: "flex",
        justifyContent: "space-between"
      },
      title: {
        width: "280px",
        fontSize: "30px",
        fontWeight: 300,
        lineHeight: "120%",
        marginBottom: "10px"
      }
    }
  })
);

const HomeView = ({ displayName, loggedIn }) => {
  const classes = styles();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [meetingId, setMeetingId] = useState(searchParams.get("meetingId"));
  useEffect(() => {
    (async () => {
      await authService.checkLoginStatus();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (meetingId?.length === 10) {
        const res = await meetingService.validMeetingID({ meetingID: meetingId?.toLowerCase() });
        if (res.isValid) {
          return setChecked(true);
        }
        setChecked(false);
      }
    })();
  }, [meetingId]);
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.logoContainer} onClick={() => console.log("HOME")}>
          <CardMedia className={classes.logo} image="images/zumaridi_logo.svg" title="logo" />
          {loggedIn && <DisplayName displayName={displayName} white />}
        </Box>
        <Box className={classes.cardContainer}>
          {/* {loggedIn ? (
            <Card className={classes.loggedInCard}>
              <CardContent>
                <Typography className={classes.title}>
                  <FormattedMessage id="room.meetingID" defaultMessage="Meeting ID" />
                </Typography>
                <Typography className={classes.details}>
                <FormattedMessage id="room.confirmMeetingI" defaultMessage="Ensure to confirm your meeting ID" />
                </Typography>
                <Input
                  placeholder="Enter meeting ID or link"
                  type="url"
                  required
                  className={classes.loggedInInput}
                  disableUnderline
                  defaultValue={meetingId}
                  endAdornment={
                    checked &&
                    meetingId.length > 1 && (
                      <InputAdornment position="end">
                        <img src="images/check-square.svg" alt="check" />
                      </InputAdornment>
                    )
                  }
                  onChange={(e) => setMeetingId(e.target.value)}
                >
                  <img src="images/question-circle.svg" alt="question" />
                </Input>
                <Button
                  className={classes.loggedInJoinButton}
                  onClick={() => {
                    history.push(`/${meetingId}`);
                  }}
                >
                  <FormattedMessage id="label.joinMeeting" defaultMessage="Join Meeting" />
                </Button>
              </CardContent>
            </Card>
          ) : (
            
          )} */}
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.infoIcons}>
                <IconButton>
                  <img src="images/question-circle.svg" alt="question" />
                </IconButton>
                <IconButton>
                  <img src="images/message-info.svg" alt="information" />
                </IconButton>
              </div>
              <Typography className={classes.title}>
                Connect Simply, Engage <b>Powerfully.</b>
              </Typography>
              <Typography className={classes.details}>
                The end-to-end video engagement platform enabling organizations to create meetings
                and webinars at scale.
              </Typography>
              <div className={classes.inputContainer}>
                <Input
                  placeholder="Meeting ID"
                  type="url"
                  required
                  className={classes.input}
                  defaultValue={meetingId}
                  disableUnderline
                  endAdornment={
                    checked &&
                    meetingId.length > 1 && (
                      <InputAdornment position="end">
                        <img src="images/check.svg" alt="check" />
                      </InputAdornment>
                    )
                  }
                  onChange={(e) => setMeetingId(e.target.value)}
                >
                  <img src="images/question-circle.svg" alt="question" />
                </Input>
                <Button
                  className={classes.joinButton}
                  onClick={() => {
                    history.push(`/${meetingId}`);
                  }}
                >
                  <FormattedMessage id="label.join" defaultMessage="Join" />
                </Button>
              </div>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => {
                  window.open(`${env.CLIENT_BASE_URL}`);
                }}
                className={classes.dashboardButton}
              >
                <img src="images/dashboard-image.svg" alt="home" />
                &nbsp; &nbsp; Go to dashboard
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

HomeView.propTypes = {
  displayName: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired
};
const mapStateToProps = (state) => {
  return {
    displayName: state.settings.displayName,
    loggedIn: state.me.loggedIn
  };
};
export default connect(mapStateToProps)(HomeView);
